exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-admin-jsx": () => import("./../../../src/pages/admin/[...].jsx" /* webpackChunkName: "component---src-pages-admin-jsx" */),
  "component---src-pages-contactus-jsx": () => import("./../../../src/pages/contactus.jsx" /* webpackChunkName: "component---src-pages-contactus-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-publisher-jsx": () => import("./../../../src/pages/publisher/[...].jsx" /* webpackChunkName: "component---src-pages-publisher-jsx" */),
  "component---src-pages-swagger-jsx": () => import("./../../../src/pages/swagger.jsx" /* webpackChunkName: "component---src-pages-swagger-jsx" */),
  "component---src-pages-welcome-jsx": () => import("./../../../src/pages/welcome.jsx" /* webpackChunkName: "component---src-pages-welcome-jsx" */)
}

