import React, { createContext, useContext, useEffect, useState  } from "react";
import { auth } from "../Firebase/Firebase";
import { onAuthStateChanged } from "firebase/auth";
import {navigate} from "gatsby";

const defaultContext = {
    currentUser: null,
    fetchAuth: async () => { console.error('fetchAuth is not defined yet'); return '' },
}

export const AuthContext = createContext(defaultContext);
// export const useAuth = () => useContext(AuthContext);

const endPoint = `${process.env.GATSBY_API_URL || 'http://localhost:8788/api/'}`;


const AuthProvider = ({ children }) => {

    const [currentUser, setCurrentUser] = useState(null);

    const fetchAuth = async (resource, init) => {
//        console.log('fetchAuth called')
        try {
            const _init = init || {}
            const headers = _init.headers || {}
            if (!headers['Content-type'])
                headers['Content-type'] = 'application/json';
            if (!!currentUser) {
                const token = await currentUser.getIdToken();
                headers['Authorization'] = 'Bearer ' + token;
                const noXpub = _init['no-x-pubid']
                delete _init['no-x-pubid']
                if ((typeof window !== 'undefined') && !noXpub) {
                    const storedUser = JSON.parse(localStorage.getItem('user'));
                    if (storedUser && storedUser.UserId) {
                        headers['x-pubid'] = storedUser.UserId;
                    }
                }
            }
            _init.headers = headers
            const apiResource = endPoint + resource
            const response = await fetch(apiResource, _init)
            if (response.status === 401) {
                console.log("Status 401")
                await navigate('/login/')
            }
            if (headers['Content-type'] === 'application/json') {
                return await response.json();
            }
            return await response.text();
        } catch (error) {
            console.error("fetchAuth error", error)
            return ''
        }
    }

    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            setCurrentUser(user);
        });
    }, []);

    return (
        <AuthContext.Provider value={{currentUser, setCurrentUser, fetchAuth}}>
            {children}
        </AuthContext.Provider>
    );
};

export function useAuthValue(){
    return useContext(AuthContext)
}

export default AuthProvider;