const initialState = {
  selectedNetworks: [],
  savedPlacements: [],
  currentPlacement: null,
  openAdSlug: null,
};

const SELECT_NETWORK = "adNetworks/selectNetwork";
const DESELECT_NETWORK = "adNetworks/deselectNetwork";
const SET_OPEN_AD_SLUG = "adNetworks/setOpenAdSlug";
const CLEAR_OPEN_AD_SLUG = "adNetworks/clearOpenAdSlug";
const ADD_SAVED_PLACEMENT = "adNetworks/addSavedPlacement";
const REMOVE_SAVED_PLACEMENT = "adNetworks/removeSavedPlacement";
const SET_CURRENT_PLACEMENT = "adNetworks/setCurrentPlacement";
const CLEAR_CURRENT_PLACEMENT = "adNetworks/clearCurrentPlacement";

export const addSavedPlacement = (placement) => ({
  type: ADD_SAVED_PLACEMENT,
  payload: placement,
});

export const removeSavedPlacement = (placement) => ({
  type: REMOVE_SAVED_PLACEMENT,
  payload: placement,
});

export const setCurrentPlacement = (placement) => ({
  type: SET_CURRENT_PLACEMENT,
  payload: placement,
});

export const clearCurrentPlacement = () => ({
  type: CLEAR_CURRENT_PLACEMENT,
});

export const selectNetwork = (network) => ({
  type: SELECT_NETWORK,
  payload: network,
});

export const deselectNetwork = (network) => ({
  type: DESELECT_NETWORK,
  payload: network,
});

export const setOpenAdSlug = (slug) => ({
  type: SET_OPEN_AD_SLUG,
  payload: slug,
});

export const clearOpenAdSlug = () => ({
  type: CLEAR_OPEN_AD_SLUG,
});

export const adNetworksReducer = (state = initialState, action) => {
  switch (action.type) {
    case SELECT_NETWORK:
      return {
        ...state,
        selectedNetworks: [...state.selectedNetworks, action.payload],
      };
    case DESELECT_NETWORK:
      return {
        ...state,
        selectedNetworks: state.selectedNetworks.filter(
          (net) => net.NetworkId !== action.payload.NetworkId
        ),
      };
    case SET_OPEN_AD_SLUG:
      return {
        ...state,
        openAdSlug: action.payload,
      };
    case CLEAR_OPEN_AD_SLUG:
      return {
        ...state,
        openAdSlug: null,
      };
    case ADD_SAVED_PLACEMENT:
      return {
        ...state,
        savedPlacements: [...state.savedPlacements, action.payload],
      };
    case REMOVE_SAVED_PLACEMENT:
      return {
        ...state,
        savedPlacements: state.savedPlacements.filter(
          (placement) => placement.name !== action.payload.name
        ),
      };
    case SET_CURRENT_PLACEMENT:
      return {
        ...state,
        currentPlacement: action.payload,
      };
    case CLEAR_CURRENT_PLACEMENT:
      return {
        ...state,
        currentPlacement: null,
      };
    default:
      return state;
  }
};
