import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { adNetworksReducer } from "./adNetworksSlice";
import booleanReducer from "./booleanSlice";

const rootReducer = combineReducers({
  adNetworks: adNetworksReducer,
  boolean: booleanReducer,
});

const store = configureStore({
  reducer: rootReducer,
});

export default store;
